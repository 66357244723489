<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p></p>
        <el-button type="primary" class="btn" @click="dialogFormVisible = true">+ 添加学生</el-button>
      </div>
      <div class="query">
        <div class="queryLeft">

          <div style="width: 200px; float: left">
            省份：
            <el-select v-model="province" class="select" clearable @change="provincese">
              <el-option v-for="item in province_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 200px; float: left">
            城市：
            <el-select v-model="city" class="select" clearable @change="areaChange">
              <el-option v-for="item in city_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 200px; float: left">县区：
            <el-select v-model="area" class="select" clearable>
              <el-option v-for="item in area_data" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div style="width: 260px;">
            <i class="el-icon-search" style="width: 20px;"></i>
            <el-input class="select" v-model.trim="searchname" placeholder="清输入学生编号">
            </el-input>
          </div>
        </div>
        <div class="queryRight">
          <el-button @click="reset()">重置</el-button>
          <el-button type="primary" @click="searchStu(1)">查询</el-button>
        </div>
      </div>
      <el-table :data="stueyelist" border style="width: 100%" @selection-change="handleSelectChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="r_name" label="备注名"></el-table-column>
        <el-table-column prop="stuNum" label="学生编号"></el-table-column>
        <el-table-column prop="stime" label="授权时间"></el-table-column>
        <el-table-column prop="etime" label="到期时间"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="detail(scope.row)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px;">
        <el-button class="del" :style="{backgroundColor:color}" @click="del()">删除</el-button>
      </div>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
          background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="添加学生" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入学生编号">
          <el-input style="width: 260px; float: left" placeholder="请输入学生编号" v-model="stu_eye_new.stuNum">
          </el-input>
        </el-form-item>
        <el-form-item label="请输入授权码">
          <el-input style="width: 260px; float: left" placeholder="请输入授权码" v-model="stu_eye_new.code">
          </el-input>
        </el-form-item>
        <el-form-item label="请输入备注姓名">
          <el-input style="width: 260px; float: left" placeholder="请输入备注姓名" v-model="stu_eye_new.r_name">
          </el-input>
        </el-form-item>
        <el-form-item label="请输入备注   ">
          <el-input style="width: 260px; float: left" placeholder="备注" v-model="stu_eye_new.remark">
          </el-input>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false">取消</el-button>
          <el-button type="primary" @click="add_stu_eye()">添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
  import {
    machineSsq,
    stuEyeDel,
    stuEyeSearchList
  } from '@/api/agentApi.js'
  import {
    add_stu_eye
  } from '@/api/agent_newApi.js'

  export default {
    data() {
      return {
        stueyelist: [], // 学生列表
        stu_eye_new: {
          stuNum: '',
          code: '',
          r_name: '',
          remark: '',
        },
        province_data: [], // 省
        province: "",
        city_data: [], // 市
        city: "",
        area_data: [], // 区
        area: "",
        currpage: 1, // 初始页
        pagesize: 10, // 每页显示条目个数
        CountNum: 0, // 总条数
        search: "",
        searchname: "",
        searchstatus: 0, //查询状态
        searchprovince: 0, //查询状态
        searchcity: 0, //查询状态
        searcharea: 0, //查询状态
        dialogFormVisible: false, // 添加设备弹窗
        checkIds: '',
        checkRemark: '',
        color: '',
      }
    },
    created() {
      if (localStorage.getItem('healthlist') != null) {
        this.currpage = parseInt(localStorage.getItem('healthlist'));
        localStorage.setItem('healthlist', null)
      }
    },
    mounted() {
      this.Ssq(); // 查询省市区
      this.stuList(this.currpage); // 查询省市区
    },
    methods: {
      reset() { //重置按钮
        this.search = '';
        this.searchname = '';
        this.searchstatus = 0;
        this.province = '';
        this.city = '';
        this.area = '';
        this.stuList(this.currpage);
      },
      handleClick(row) {
        console.log(row);
      },
      handleSelectChange(val) {

        this.checkIds = val.map(item => {
          return item.id
        }).join(',');
        this.checkRemark = val.map(item => {
          return item.remark
        }).join(',');
        // console.log("选中触发", this.checkIds);
        if (this.checkIds.length > 0) {
          this.color = '#F73131'
        } else {
          this.color = '#f0f2f5';
        }
      },
      //添加学生
      add_stu_eye() {
        if (this.stu_eye_new.stuNum == '') {
          this.$message.error("必填学生编号");
          return;
        } else if (this.stu_eye_new.code == '') {
          this.$message.error("必填授权码");
          return;
        } else if (this.stu_eye_new.r_name == '') {
          this.$message.error("必填备注名");
          return;
        } else {
          const data = this.stu_eye_new;
          add_stu_eye(data).then(res => {
            if (res.status.code == 1) {
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        }
      },
      //查询所有学生
      stuList(currpage) {
        const data = {
          limit: this.pagesize,
          page: this.currpage
        }
        stuEyeSearchList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.stueyelist = res.data.List

          //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
          this.currpage = currpage
        }).catch(err => {
          console.log(err)
        })
      },
      searchStu(currpage) { // 点击查询用户列表 只用点击查询时候 用这个方法
        this.loading = true
        this.search = this.searchname;
        this.searchstatus = 1;

        if (this.province != "") {
          this.searchprovince = this.province
        } else {
          this.searchprovince = "";
        }

        if (this.area != "") {
          this.searcharea = this.area
        } else {
          this.searcharea = "";
        }

        if (this.city != "") {
          this.searchcity = this.city
        } else {
          this.searchcity = "";
        }
        const data = {
          page: currpage, // 第一次查询时候默认1
          limit: this.pagesize, // 总数
          stunum: this.search, // 查询编号
          province: this.searchprovince, // 查询省
          city: this.searchcity, // 查询市
          area: this.searcharea, // 查询区
        }

        stuEyeSearchList(data).then(res => {
          this.stueyelist = res.data.List // 已审核用户列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
          //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
          this.currpage = currpage
        }).catch(err => {
          console.log(err)
        })
      },
      stuListSearchHandle(currpage) { // 带有查询时分页 当有条件查询 分页时走这个方法
        this.loading = true

        const data = {
          page: currpage, // 第一次查询时候默认1
          limit: this.pagesize, // 总数
          search: this.search, // 查询
          province: this.searchprovince, // 查询省
          city: this.searchcity, // 查询市
          area: this.searcharea, // 查询区
        }

        stuEyeSearchList(data).then(res => {
          this.stueyelist = res.data.List // 已审核用户列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
          //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
          this.currpage = currpage
        }).catch(err => {
          console.log(err)
        })
      },
      handleCurrentChange(val) { // 分页
        if (this.search == "" && this.province == "" && this.city == "" && this.area == "") {
          this.currpage = val
          this.stuList(val)
        } else {
          if (this.searchstatus == 1) {
            this.currpage = val
            this.stuListSearchHandle(val)
          } else {
            this.currpage = val
            this.stuList(val)
          }

        }
      },
      Ssq() {
        const data = {
          ssq: 1
        }
        machineSsq(data).then(res => {
          this.province_data = res.data.province // X省
        }).catch(err => {
          console.log(err)
        })
      },
      provincese(val) {
        if (val != 0) {
          this.citySearch(val)
        }
      },
      citySearch(val) {
        const datas = {
          pid: val,
        }
        machineSsq(datas).then(res => {
          this.city_data = res.data.city;
        }).catch(err => {
          console.log(err)
        })
      },
      areaSearch(val) {
        const datas = {
          id: val,
        }
        adminArea(datas).then(res => {
          this.areaList = res.data;
        }).catch(err => {
          console.log(err)
        })
      },
      areaChange(val) {
        const datas = {
          cid: val,
        }
        this.area = '';
        machineSsq(datas).then(res => {
          this.area_data = res.data.area;
        }).catch(err => {
          console.log(err)
        })
      },
      del() {
        if (this.checkIds.length > 0) {
          const data = {
            id: this.checkIds,
            name: this.checkRemark
          }
          stuEyeDel(data).then(res => {
            if (this.search == "" && this.province == "" && this.city == "" && this.area == "") {
              this.stuList(this.currpage)
            } else {
              if (this.searchstatus == 1) {
                this.stuListSearchHandle(this.currpage)
              } else {
                this.stuList(this.currpage)
              }
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          alert('删除为空！');
        }
      },
      detail(row) { // 点击详情
        localStorage.setItem('Route', 'health') // 存跳转标识
        localStorage.setItem('healthlist', this.currpage) // 存跳转页面
        this.$router.push({
          name: 'health_detail',
          params: {
            student_id: row.sid
          }
        })
      },

    }
  }

</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        margin-left: 32px;
      }

      .btn {
        display: flex;
        align-items: center;
        width: 109px;
        height: 32px;
        opacity: 1;
        border-radius: 2px;
      }
    }

    .del {
      float: left,

    }

    .query {
      width: 98%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      padding-left: 32px;

      .queryLeft {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        div {
          width: 300px;
          line-height: 50px;
          text-align: left;

          .input,
          .select {
            width: 60%;
          }
        }
      }
    }
  }

</style>
